/* 
断点指南：
默认是为1600px以上写

中等屏幕
@media (min-width: 1200px) and (max-width: 1600px) {
  
}

小屏幕
 @media  (max-width: 1199px) {
  
}  */

.mainhome {
  background-color: #5827ce;
  font-family: "Microsoft YaHei", sans-serif;
}

/* 动画 */

@keyframes slowMoveFromLeftToRight {
  from {
    transform: translateX(-100%); /* 初始位置，比如从左边完全移出屏幕 */
    opacity: 0; /* 初始透明度为0，即不可见 */
  }
  to {
    transform: translateX(0); /* 结束位置，比如移动到正常位置 */
    opacity: 1; /* 结束透明度为1，即完全可见 */
  }
}

@keyframes slowMoveFromRightToLeft {
  from {
    transform: translateX(0); /* 初始位置，正常位置 */
    opacity: 1; /* 初始透明度为1，即完全可见 */
  }
  to {
    transform: translateX(-100%); /* 结束位置，从右边完全移出屏幕 */
    opacity: 0; /* 结束透明度为0，即不可见 */
  }
}

/* 基础 */

.generate_h3_title {
  color: #fff !important;
  font-size: 2.4rem;
}

/* 导航 和 hero section */

#hero_nav {
  height: 100vh;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* background: linear-gradient(to bottom, #5827ce, #471eaa); */
  /* background-color: #5827ce; */
  /* background-image: url("../assets/三国风插画/用户反馈背景1.webp"); */
  background-image: url("../assets/三国风插画/用户反馈背景1.webp"),
    linear-gradient(#5827ce, #471eaa);
}

#导航 {
  display: flex;

  justify-content: flex-end;
  gap: 2.4rem;
  padding: 0 2.4rem;
}

.navHref {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 1.2rem;
}

.navHref:hover {
  cursor: pointer;
}

.hero {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

#hero腾起的马 {
  width: 100%;
  animation: slowMoveFromLeftToRight 3s ease-out forwards; /* 应用动画，持续5秒，缓出效果，保持结束状态 */
}

#hero右侧 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
}

#heroTitle {
  font-size: 8.8rem;
  color: #fff;
  grid-column: 1/-1;
  grid-row: 1/3;
  align-self: center;
  justify-self: center;
  font-family: "jianhao";
  font-weight: 100;
}

.btns {
  margin-top: 3.6rem;
  grid-column: 1/-1;
  grid-row: 3/4;
  justify-self: center;
  align-items: center;
  display: flex;
  gap: 6.8rem;
}

#main_button {
  padding: 12px 68px;
  font-size: 1.2rem;
  /* border-radius: 50% 20% / 10% 40%; */
  border-radius: 35px;
  background: linear-gradient(to right, rgb(38, 215, 212), rgb(0, 136, 254));
  color: white;
  text-decoration: none;
  font-family: "微软雅黑";
  transition: all 0.5s;
  /* border: 1px solid black; */
}

#main_button:hover {
  background: linear-gradient(to right, rgb(0, 136, 254), rgb(38, 215, 212));
  cursor: pointer;
}

#aside_button {
  padding: 12px 68px;
  font-size: 1.2rem;
  /* border-radius: 30px 20% / 10% 40%; */
  border-radius: 35px;
  background: linear-gradient(to right, rgb(205, 69, 255), rgb(115, 104, 255));
  color: white;
  text-decoration: none;
  font-family: "微软雅黑";
  /* border: 1px solid black; */
}

#aside_button:hover {
  background: linear-gradient(to left, rgb(205, 69, 255), rgb(115, 104, 255));
  cursor: pointer;
}

@media (min-width: 1024px) and (max-width: 1439px) {
  #heroTitle {
    font-size: 6.2rem;
  }
  #main_button,
  #aside_button {
    padding: 10px 38px;
    font-size: 1.1rem;
  }
}

@media (max-width: 1023px) {
  #heroTitle {
    font-size: 5.2rem;
  }
  #main_button,
  #aside_button {
    padding: 8px 32px;
    font-size: 1.1rem;
  }
}

/* 显卡工作站 */

#显卡工作站 {
  position: relative;
  height: 100vh;
  background: url("../assets/三国风插画/用户反馈背景1.webp"),
    linear-gradient(#471eaa, #361586);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#显卡工作站_三兵马 {
  width: 45%;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 3;
}

#显卡工作站_兵马 {
  width: 48%;
  position: absolute;
  left: 0;
  bottom: 0;
}

#显卡工作站渐变 {
  z-index: 0;
  background: linear-gradient(to right, #0f6fa1, #722fad);
  height: 50%;
  width: 93%;
  right: 0;
  border-radius: 50px 0 0 0;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  /* background-image: url("../assets/三国风插画/显卡工作站_海浪.webp"); */
}

#显卡工作站渐变::after {
  content: ""; /* 伪元素的内容为空 */
  position: absolute; /* 绝对定位，相对于#显卡工作站渐变元素 */
  bottom: 0; /* 从底部开始 */
  left: 0; /* 从左边开始 */
  width: 100%; /* 宽度与父元素相同 */
  height: 10%; /* 高度与父元素相同 */
  background: linear-gradient(to bottom, transparent, transparent),
    repeating-linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.1),
      /* 半透明白色条纹 */ rgba(255, 255, 255, 0.1) 4px,
      /* 四个像素后变为透明 */ transparent 4px,
      /* 四个像素的透明间隔 */ transparent 8px /* 下一个半透明白色条纹开始 */
    );
  background-size: 100% 8px; /* 设置背景大小为8像素，以匹配条纹的重复间隔 */
  z-index: -1; /* 确保伪元素在渐变背景之下 */
}

#显卡工作站文字 {
  position: absolute;
  top: 68px;
  left: 48px;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

#显卡工作站标题 {
  color: #fff !important;
  font-size: 4.8rem;
}

#显卡工作站文字 h3 {
  font-size: 2.4rem;
  font-weight: 500;
}

#显卡工作站_海浪 {
  width: 65%;
  height: 100%;
}

@media (min-width: 1024px) and (max-width: 1589px) {
  #显卡工作站标题 {
    font-size: 3.6rem;
  }

  #显卡工作站文字 h3 {
    font-size: 1.8rem;
  }
}

@media (max-width: 1023px) {
  #显卡工作站标题 {
    font-size: 3.2rem;
  }

  #显卡工作站文字 h3 {
    font-size: 1.4rem;
  }
}

/* 三大步骤 */

#三大步骤 {
  background-color: #260d61;
  position: relative;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  background-image: url("../assets/三国风插画/三大步骤背景.webp"),
    linear-gradient(#361586, #260d61);

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#三大步骤_红龙 {
  width: 100%;
}

#步骤s {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4.8rem;
}

.步骤 {
  text-align: center;
  color: #fff;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 1.2rem;
  width: 70%;
}
.步骤 > h3 {
  font-size: 3.2rem;
  font-weight: 700;
}

.步骤 > p {
  font-size: 1.6rem;
}

@media (min-width: 1200px) and (max-width: 1600px) {
  #步骤s {
    gap: 2.6rem;
  }
  .步骤 > h3 {
    font-size: 2.6rem;
  }
  .步骤 > p {
    font-size: 1.4rem;
  }
}

@media (max-width: 1199px) {
  #步骤s {
    gap: 1.8rem;
  }
  .步骤 > h3 {
    font-size: 2.2rem;
  }
  .步骤 > p {
    font-size: 1.2rem;
  }
}

/* 六大优势 */

.优势 {
  background-color: #110331;
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin-bottom: 1.2rem;
  align-items: center;
}

.优势1 {
  background-image: url("../assets/三国风插画/六大优势背景1.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.优势2 {
  background-image: url("../assets/三国风插画/六大优势背景2.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.优势3 {
  background-image: url("../assets/三国风插画/六大优势背景3.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.优势:last-child {
  margin-bottom: 0;
}

#六大优势_剑客,
#六大优势_兵马,
#六大优势_凤凰 {
  width: 95%;
  grid-row: 1/3;
  grid-column: 2/3;
  justify-self: center;
}

#六大优势_兵马 {
  justify-self: start;
  width: 100%;
  grid-column: 1/2;
}

#六大优势_凤凰 {
  width: 95%;
  justify-self: end;
}

.优势文案 {
  width: 50%;
  justify-self: center;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  margin-bottom: 1.2rem;
}

.优势 > .优势文案:nth-of-type(2) {
  align-self: self-start;
}

.优势文案 p {
  font-size: 2rem;
  color: #fff;
}

.六大优势_火焰 {
  width: 80%;
  grid-column: 2/10;
  grid-row: 1/3;
}

#六大优势_AI,
#六大优势_性能,
#六大优势_定制化,
#六大优势_秘籍,
#六大优势_人性化,
#六大优势_平等 {
  width: 120%;
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .优势文案 p {
    font-size: 1.4rem;
  }
  #六大优势_AI,
  #六大优势_性能,
  #六大优势_定制化,
  #六大优势_秘籍,
  #六大优势_人性化,
  #六大优势_平等 {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .优势文案 p {
    font-size: 1.3rem;
  }
  #六大优势_AI,
  #六大优势_性能,
  #六大优势_定制化,
  #六大优势_秘籍,
  #六大优势_人性化,
  #六大优势_平等 {
    width: 100%;
  }
}

/* .优势文案 h3 {
  font-size: 2.4rem;
  color: #fff;
} */

/* 五个商品 */

#五个商品 {
  position: relative;
  height: 100vh;
  background-color: #1a0846;
  background-image: url("../assets/三国风插画/五个商品背景.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#五个商品 .generate_h3_title {
  padding-top: 2.4rem;
  text-align: center;
}

#商品s {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 2, 1fr;
  gap: 1.8rem;
  margin: 2.4rem auto;
  width: 95%;
}

.商品 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(10, 1fr);
}

.商品 h3 {
  z-index: 2;
  color: #fff;
  font-size: 2.2rem;
  grid-row: 2/3;
  grid-column: 1/2;
  justify-self: center;
}

.商品 ul {
  list-style: none;
  grid-column: 1/2;
  grid-row: 6/-3;
  justify-self: center;
  font-weight: 700;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 1.1rem;
}

.商品 button {
  grid-column: 1/2;
  grid-row: 9/10;
  width: 50%;
  height: 75%;
  justify-self: center;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #fff;
  background: linear-gradient(to bottom, #4d42d9, #952ab3);
  color: #fff;
  align-self: flex-end;
  font-size: 1.2rem;
}

.商品 button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.宝物 {
  width: 40%;
  z-index: 10;
  grid-column: 1/2;
  grid-row: 3/6;
  justify-self: center;
}

.商品格 {
  width: 100%;
  grid-row: 1/-1;
  grid-column: 1/2;
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .商品 ul {
    font-size: 1rem;
    grid-row: 5/-4;
    /* gap: 4px; */
  }
  .宝物 {
    width: 35%;
    grid-row: 3/5;
  }

  .商品 h3 {
    font-size: 1.8rem;
  }
  .商品 button {
    grid-row: -4/-3;
  }
}

@media (max-width: 1350px) {
  .商品 ul {
    font-size: 0.9rem;
    grid-row: 5/-4;
    /* gap: 4px; */
  }
  .宝物 {
    width: 35%;
    grid-row: 3/5;
  }

  .商品 h3 {
    font-size: 1.8rem;
  }
  .商品 button {
    grid-row: -4/-3;
  }
}

@media (max-width: 1250px) {
  .商品 ul {
    font-size: 0.7rem;
    grid-row: 5/-4;
    /* gap: 4px; */
  }
  .宝物 {
    width: 25%;
    grid-row: 3/5;
  }

  .商品 h3 {
    font-size: 1.8rem;
  }
  .商品 button {
    grid-row: -4/-3;
  }
  .商品 h3 {
    font-size: 1.4rem;
  }
}

@media (max-width: 1100px) {
  #商品s {
    gap: 8px;
  }

  .商品 ul {
    font-size: 0.7rem;
    grid-row: 5/-4;
    font-weight: 500;
    /* gap: 4px; */
  }
  .宝物 {
    width: 25%;
    grid-row: 3/5;
  }

  .商品 h3 {
    font-size: 1.4rem;
  }
  .商品 button {
    grid-row: -4/-3;
  }
  .商品 h3 {
    font-size: 1.2rem;
  }

  .商品 button {
    font-size: 0.75rem;
  }
}

@media (max-width: 1000px) {
  #商品s {
    gap: 8px;
  }
  .商品 ul {
    font-size: 0.6rem;
    grid-row: 4/-5;
    /* gap: 4px; */
  }
  .宝物 {
    width: 20%;
    grid-row: 3/4;
  }

  .商品 h3 {
    font-size: 1.4rem;
  }
  .商品 button {
    grid-row: -4/-4;
  }
  .商品 h3 {
    font-size: 1.2rem;
  }

  .商品 button {
    font-size: 0.7rem;
    grid-row: -5/-4;
    padding: 2px 4px;
  }
}

/* 用户反馈 */

/* #用户反馈 {
  background-image: url("../assets/三国风插画/用户反馈背景.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #5827ce;
} */

#用户反馈标题 {
  font-size: 3.6rem;
  text-align: center;
  margin-bottom: 2.4rem;
  padding-top: 2.4rem;
  color: #fff;
}

.反馈 {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(5, 1fr);
  height: 100vh;
  background-color: #1a0846;
  background-image: url("../assets/三国风插画/用户反馈背景1.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.反馈2 {
  background-image: url("../assets/三国风插画/用户反馈背景2.webp");
}

#用户反馈_腾起的马,
#用户反馈_向左的士兵,
#用户反馈_向右的士兵 {
  width: 80%;
  grid-column: 1/7;
  grid-row: 1/-1;
  z-index: 10;
}

#用户反馈_向左的士兵 {
  width: 65%;
  grid-column: 3/-1;
  justify-self: end;
}

#用户反馈_向右的士兵 {
  width: 80%;
}

#用户反馈_图像在左文本框 {
  width: 90%;
  height: 80%;
  grid-column: 4/-1;
  grid-row: 3/-1;
}

#用户反馈_图像在右文本框 {
  width: 85%;
  height: 80%;
  grid-column: 1/-3;
  grid-row: 3/-1;
  justify-self: end;
}

.反馈文案 {
  grid-column: 6/10;
  grid-row: 3/5;
  z-index: 11;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.8rem 4.8rem;
}

.反馈文案 p {
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.6;
}

#用户反馈_图像在右文本框 + .反馈文案 {
  grid-column: 3/7;
  padding-left: 0;
  padding-right: 12.8rem;
}

#用户反馈_向右的士兵 + .反馈文案 {
  padding-left: 0;
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .反馈文案 {
    padding: 1.8rem 0rem;
  }

  #用户反馈_图像在右文本框 + .反馈文案 {
    grid-column: 3/7;
    padding-left: 0;
    padding-right: 4.8rem;
  }

  #用户反馈_腾起的马,
  #用户反馈_向左的士兵,
  #用户反馈_向右的士兵 {
    width: 85%;
  }
  .反馈文案 p {
    font-size: 1.1rem;
  }

  #用户反馈_向右的士兵 + .反馈文案 {
    padding-left: 0;
  }

  #用户反馈_向右的士兵 {
    width: 85%;
  }

  #用户反馈_向左的士兵 {
    width: 68%;
  }
}

@media (max-width: 1199px) {
  .反馈文案 {
    padding: 1.8rem 0.4rem;
  }

  #用户反馈_图像在右文本框 + .反馈文案 {
    grid-column: 3/7;
    padding-left: 0;
    padding-right: 4.8rem;
  }

  #用户反馈_腾起的马,
  #用户反馈_向左的士兵,
  #用户反馈_向右的士兵 {
    width: 86%;
  }
  .反馈文案 p {
    font-size: 1rem;
  }

  #用户反馈_向右的士兵 + .反馈文案 {
    padding-left: 0;
  }

  #用户反馈_向右的士兵 {
    width: 90%;
  }

  #用户反馈_向左的士兵 {
    width: 68%;
  }

  .反馈文案 h3 {
    font-size: 1.8rem;
  }
}

@media (max-width: 1000px) {
  .反馈文案 p {
    font-size: 0.8rem;
  }

  .反馈文案 h3 {
    font-size: 1.4rem;
  }
}
