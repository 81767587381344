.container {
  display: grid;
  grid-template-columns: 1fr;
}

.container article {
  padding: 0.8rem;
}

.container article h2 {
  font-size: 1.8rem;
  margin-bottom: 1.2rem;
}

.btn {
  border-radius: 5px;
}

.btn:hover {
  cursor: pointer;
  opacity: 0.8;
}

.btns {
  display: flex;
  gap: 1rem;
}

/*  左侧 （移动端为下侧） */

body article:first-child {
  grid-row: 1/2;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 6px;
}

.block {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 1.2rem;
  gap: 1.2rem;
  justify-content: center;
  align-content: center;
}

.block label {
  font-size: 1.2rem;
}

.block input {
  justify-self: left;
  font-size: 1.1rem;
  padding: 2px 4px;
}

.block select {
  justify-self: left;
  padding: 2px 4px;
  font-size: 1.1rem;
}

#附加信息 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

#附加信息 p {
  font-size: 1.2rem;
  justify-self: center;
}

#附加信息 h2 {
  grid-column: 1/5;
}

.附加信息表格 {
  grid-column: 1/5;
  margin-top: 1.2rem;
}

.charge_type {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.4rem;
}

@media (max-width: 900px) {
  .form {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }

  .block {
    margin-bottom: 0px;
    justify-items: start;
  }
  .block label {
    font-size: 1rem;
  }
  .block input {
    font-size: 1rem;
    padding: 2px 4px;
  }

  #附加信息 {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  #附加信息 h2 {
    grid-column: 1/3;
    margin-top: 1.2rem;
  }

  #附加信息 p {
    font-size: 0.8rem;
  }

  .附加信息表格 {
    grid-column: 1/3;
    margin-top: 1.2rem;
  }
}

/* 右侧 */

.rightContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 6px;
}

.tuodong_block {
  background-color: #8ce99a;
  padding: 6px 8px;
  border-radius: 4.8rem;
  margin-bottom: 4px;
}

.tuodong_block p {
}

.running {
  grid-column: 1/2;
  padding: 1.2rem 0.8rem;
  border-radius: 2rem;
  border: 2px #8ce99a solid;
  margin-bottom: 1.2rem;
}

.able {
  grid-column: 2/3;
  padding: 1.8rem 1.2rem;
  border-radius: 2rem;
  border: 2px #495057 solid;
  margin-bottom: 1.2rem;
}

.finished {
  padding: 2.4rem 1.2rem;
  border-radius: 2rem;
  border: 2px #f76707 solid;
  margin-bottom: 1.2rem;
  grid-column: 1/3;
  gap: 1.2rem;
  width: 100%;
  grid-column: 3/4;
}

.unable {
  padding: 2.4rem 1.2rem;
  border-radius: 2rem;
  border: 2px #dee2e6 solid;
  grid-column: 1/4;
}

.taskTitle {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.nest1 {
  padding: 1.2rem;
  border: 3px black;
  border-style: dotted;
  border-radius: 1rem;
  margin-bottom: 1.2rem;
  display: grid;
}

.nest1 h3 {
  font-size: 1.2rem;
  margin-bottom: 1.2rem;
}

/* 设置finished的block */

.finished .nest1 {
  width: 100%;
}

.finished .nest1 .nest2 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.2rem;
}

.finished_block {
  display: block;
  border-radius: 1.2rem;
  background-color: #f08c00;
  width: 100%;
  padding: 1.2rem;
  overflow: hidden;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.205);
}

.finished_miaoshu {
  margin-top: 0.6rem;
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;
}

.finished_miaoshu p {
  font-size: 1rem;
  font-weight: normal;
}

.trashIcon {
  display: block;
  position: absolute;
  right: 0;
  top: -3rem;
  cursor: pointer;
}

.problem_finished {
  background-color: #ffa8a8;
}

.bigProblem_finished {
  background-color: #c92a2a;
}

@media (max-width: 900px) {
  .container {
    grid-template-columns: 1fr;
  }

  .tuodong_block p {
    font-size: 8px;
  }

  .nest1 {
    padding: 4px 8px;
  }

  body article:first-child {
    grid-row: 1/2;
  }

  .rightContainer {
    grid-template-columns: 1fr 1fr;
  }

  .running {
    grid-column: 1/2;
    padding: 6px;
  }

  .wait {
    grid-column: 1/3;
  }
  .able {
    grid-column: 2/3;
    padding: 6px;
  }

  .finished {
    grid-column: 1/3;
    width: 100%;
  }

  .finished_block {
    width: 100%;

    grid-column: 1/3;
  }
  .taskTitle {
    font-size: 1rem;
    margin-bottom: 10px;
  }
}
