/* general通用 */

.container {
  width: 95%;
  margin: 0, auto;
}

.generalh2 {
  margin-bottom: 1.2rem;
}

#标题搜索框一体 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  align-items: center;
  margin-bottom: 1.8rem;
}

@media (max-width: 900px) {
  .container {
    width: 90%;
    margin: 0 auto;
  }
}

/* 上部分 进行中的Business*/

.computer {
  display: block;
}

.moblie {
  display: none;
}

.feedback:hover {
  cursor: pointer;
}

.dengdaichuli {
  margin-top: 1.8rem;
}

.little_data {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 900px) {
  .computer {
    display: none;
  }

  .moblie {
    display: block;
    max-width: 100%;
    overflow-x: auto;
  }
}

/* 移动端 */

.mobleDisplay {
  display: none;
}

@media (max-width: 700px) {
  .mobleDisplay {
    display: inline-block;
    font-size: 2rem;
  }

  .taskTable thead {
    display: none;
  }

  .taskTable tbody {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .taskTable tbody tr {
    border-collapse: collapse;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    margin-bottom: 1.8rem;
    padding: 1rem;
    flex-wrap: nowrap;
  }

  .taskTable tbody tr td {
    font-size: 1.8rem;
  }

  .taskTable tbody tr td:last-child {
    margin-top: 5px;
    justify-content: center !important;
  }

  .taskTable tbody tr td svg {
    color: red;
    width: 3.6rem;
  }
}

/* 运行中的任务 */

.runningTask {
  margin-bottom: 9.6rem;
}

.miaoshu p {
  font-size: 1.8rem;
}

.taskBlockRunningContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

/* .BlockTaskRunning {
  background-color: #f59f00;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 1.2rem;
} */

.charge_type {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* @media (max-width: 900px) {
  .BlockTaskRunning {
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
} */

/* 等待中的任务 */

.taskBlockWatingContainer {
  margin-top: 2.4rem;
}

/* 失败的任务  */

.taskBlockFailContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-top: 3.6rem;
}

.BlockFailWating h3 {
  font-size: 1.8rem;
}

.BlockFailWating {
  background-color: #ff6b6b;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1.2rem;
}

.BlockFailWating p {
  font-size: 1.6rem;
}

@media (max-width: 900px) {
  .taskBlockRunningContainer {
    grid-template-columns: 1fr;
  }

  .taskBlockWatingContainer {
    grid-template-columns: 1fr 1fr !important;
  }

  .taskBlockFailContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
