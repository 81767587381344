* {
  font-family: "微软雅黑";
}

#page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* 头部 */

#header_part {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-top: 8px;
}

#header_part > h2 {
  font-size: 1.2rem;
  font-weight: 300;
}

#header_part > h2 > span {
  font-size: 1rem;
  font-weight: 300;
}

#header_part span {
  margin-right: 0;
}

/* 内容区 */

#内容 {
  background-color: #ededed;
  padding: 16px;
  height: 100%;
  flex-grow: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px;
}
.card p {
  display: flex;
  justify-content: space-between;
}

.包类型 {
  display: flex;
  align-items: center;
  gap: 8px;
}
