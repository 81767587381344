.input_div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center; /* 垂直居中 */
  justify-content: space-between; /* 水平方向留有一定间隔 */
  gap: 8px;
  width: 95%;
  margin: 1.6rem auto;
}

/* table */
.table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.table th {
  font-size: 1.8rem;
}

.table td {
  font-size: 1.6rem;
}

.showOnlyMoble {
  display: none;
}

@media (max-width: 700px) {
  .table tbody {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .showOnlyMoble {
    display: inline-block;
    font-size: 1.8rem;
  }

  .table thead tr {
    display: none;
  }

  .table tbody tr {
    display: flex;
    flex-direction: column;
    border: 1px solid black;

    padding: 1.2rem;
  }

  .table tbody tr td {
    margin-bottom: 6px;
    font-size: 1.8rem;
  }

  .table {
    margin-bottom: 4.8rem;
  }
}
