.container {
  margin: 0 auto;
  /* margin-top: 3.6rem; */
  /* width: 90%; */
  font-family: "ZCOOL XiaoWei", sans-serif;
}

.container h1 {
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 1.4rem;
}

@media (max-width: 700px) {
  .container h1 {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 1rem;
  }
}

/*  */

.submitContainer {
  padding: 1.2rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 700px) {
  .submitContainer {
    padding: 0;
    gap: 8px;
  }
}

/* 上传区域 */

.uploadContent {
  grid-column: 1/3;
  display: grid;
  width: 90%;
  border: 2px #33333323 dotted;
  border-radius: 8px;
  margin-top: 1.8rem;
  justify-self: center;
  display: grid;
  justify-content: center;
  gap: 1.2rem;
  padding: 1.2rem 1.6rem;
  background-color: rgb(250, 250, 250);
}

.uploadContent label {
  display: block;
  font-size: 1.2rem;
  grid-column: 2/3;
  font-size: 1.2rem;
  z-index: 100;
  gap: 0.8rem;
}

.hover_pointer {
  flex-direction: column;
  align-items: center;
  transition: all 1s;
}

#点击 {
  font-size: 1.2rem;
}

#支持 {
  color: #888;
  font-size: 1rem;
}

@media (max-width: 700px) {
  .uploadContent {
    margin-top: 0.8rem;
  }

  .uploadContent label {
    font-size: 0.8rem;
  }

  #点击 {
    font-size: 1rem;
  }

  #支持 {
    color: #888;
    font-size: 10px;
  }
}

/* 引导用户点击wifi的呼吸效果动画 */
@keyframes smoothGlow {
  0% {
    box-shadow: 0 0 1px rgba(208, 191, 255, 0.5),
      0 0 2px rgba(208, 191, 255, 0.5), 0 0 3px rgba(208, 191, 255, 0.5),
      0 0 4px rgba(208, 191, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 3px rgba(208, 191, 255, 0.7),
      0 0 6px rgba(208, 191, 255, 0.7), 0 0 9px rgba(208, 191, 255, 0.7),
      0 0 12px rgba(208, 191, 255, 0.7);
  }
  100% {
    box-shadow: 0 0 1px rgba(208, 191, 255, 0.5),
      0 0 2px rgba(208, 191, 255, 0.5), 0 0 3px rgba(208, 191, 255, 0.5),
      0 0 4px rgba(208, 191, 255, 0.5);
  }
}

.glow_animation {
  animation: smoothGlow 2s infinite; /* 2秒时间周期，无限循环 */
}

.hover_pointer:hover {
  cursor: pointer;
}

/* #upload {

  justify-self: left;
  padding: 8px 36px;
  background-color: #12b886;
  border: 0;
  font-weight: bold;
  border-radius: 5px;
  color: #fff;
} */

.block label {
  font-size: 1.6rem;
}

.block {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 5fr;
  margin-bottom: 1.2rem;
  align-items: center;
  position: relative;
}

.block input {
  width: 100%;
  padding: 6px;
  font-size: 1.2rem;
}

/* .block input::placeholder {
  font-size: 1.2rem;
} */

.yanzhengma_block {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.yanzhengma_block input {
  width: 100%;
  font-size: 1.2rem !important;
}

.yanzhengma_block canvas {
  width: 100%;
  height: 2.6rem;
}

.btnContainer {
  display: flex;
  justify-content: right;
}

#tijiao {
  background-color: rgb(95, 93, 93);
  width: 100%;
  font-weight: bold;
  border: 0;
  padding: 8px 36px;
  border-radius: 5px;
  width: 40%;
  color: #fff;
  font-size: 1.8rem;
  position: relative;
}

#tijiao:hover {
  cursor: pointer;
  transition: all 1s;
  background-color: #e8590c;
}

/* #tijiao::after {
  color: black;
  content: "请完善以上内容";
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 100;
  display: block;
  border: 1px solid black;
} */

.dontHaveOutline {
  outline: none;
}

@media (max-width: 700px) {
  .block {
    justify-content: space-between;
  }
  .block label {
    font-size: 1rem;
  }
  .block {
    margin-bottom: 0;
  }
  /* .block input {
    height: 80%;
  }
  .block input::placeholder {
    font-size: 0.8rem;
    padding: 0;
  } */
}

/* 文章 */

.post {
  margin-top: 2.4rem;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 4.8rem;
}

.post img {
  width: 80%;
}

.post h3 {
  font-size: 1.8rem;
  margin-bottom: 2.4rem;
}

.post p {
  font-size: 1.2rem;
  letter-spacing: 2px;
  line-height: 1.2;
  margin-bottom: 1.2rem;
}

.post ul {
  margin-left: 1.8rem;
}
.post ul li {
  list-style: none;
  font-size: 1.2rem;
  margin-bottom: 1.8rem;
}

@media (max-width: 700px) {
  .container {
    margin-top: 3.6rem;
    width: 80%;
  }

  #tijiao {
    background-color: #12b886;
    width: 100%;
    font-weight: bold;
    border: 0;
    padding: 6px;
    border-radius: 5px;
    color: #fff;
  }
}

/* 修改服务须知相关样式 */
.termsBlock {
  width: 100%;
  margin: 16px 0;
  display: grid;
  grid-template-columns: 4fr 5fr;
  align-items: center;
}

.termsBlock label {
  font-size: 1.6rem !important;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
}

.checkboxWrapper input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
  accent-color: #1890ff;
}

.checkboxWrapper label {
  font-size: 1.2rem !important;
  margin: 0 !important;
  cursor: pointer;
  color: #333;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
}

.checkboxWrapper a {
  color: #1890ff;
  text-decoration: none;
  font-weight: 500;
  padding: 0 2px;
}

.checkboxWrapper a:hover {
  text-decoration: underline;
}

@media (max-width: 700px) {
  .termsBlock {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .checkboxWrapper {
    padding: 8px;
  }

  .checkboxWrapper label {
    font-size: 1rem !important;
  }
}
