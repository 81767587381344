* {
  font-family: "微软雅黑";
}

/* 总类 */

.big_container {
  background-color: #2c1b77;
}

/* nav section */

#logo {
  opacity: 75%;
  width: 65px;
  margin-left: 2.4rem;
}

.nav {
  padding: 1.8rem 0;
  margin: 0 auto;
  width: 90%;
}

.nav ul {
  color: white;
  display: flex;
  gap: 4.8rem;
  list-style: none;
  font-size: 1.2rem;
  float: right;
}

.nav ul li {
  font-family: "微软雅黑";
}

.nav ul li :hover {
  cursor: pointer;
}

.nav ul li a {
  text-decoration: none;
  color: white;
}

.nav_mobile {
  display: none;
}

@media (max-width: 900px) {
  .nav {
    display: none;
  }
  .nav_mobile {
    display: block;
    padding: 4px 8px;
  }
}

/* hero section */

.hero {
  width: 95%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, 1fr);
  position: relative;
  padding-top: 4.8rem;
  overflow: hidden;
  margin-right: 0;
}

.hero_title {
  font-size: 8.8rem;
  color: white;
  font-family: "jianhao";
  font-weight: 100;
}

.hero_img {
  right: 0;
  /* transform: translate(30%, 10%); */
  overflow: hidden;
  grid-column: 2/3;
  grid-row: 1/5;
}
.desctiption {
  margin-top: 3.6rem;
  margin-left: 4.8rem;
  font-size: 1.8rem;
  color: white;
  grid-column: 1/2;
  font-family: "微软雅黑";
  line-height: 2.8rem;
  letter-spacing: 5px;
}

.btns {
  margin-top: 3.6rem;
  grid-column: 1/2;
  justify-self: center;
  align-items: center;
  display: flex;
  gap: 6.8rem;
}

#main_button {
  padding: 14px 68px;
  font-size: 1.4rem;
  /* border-radius: 50% 20% / 10% 40%; */
  border-radius: 35px;
  background: linear-gradient(to right, rgb(38, 215, 212), rgb(0, 136, 254));
  color: white;
  font-family: "微软雅黑";
  transition: all 0.5s;
}

#main_button:hover {
  background: linear-gradient(to right, rgb(0, 136, 254), rgb(38, 215, 212));
  cursor: pointer;
}

#aside_button {
  padding: 14px 68px;
  font-size: 1.4rem;
  /* border-radius: 30px 20% / 10% 40%; */
  border-radius: 35px;
  background: linear-gradient(to right, rgb(205, 69, 255), rgb(115, 104, 255));
  color: white;
  font-family: "微软雅黑";
}

#aside_button:hover {
  background: linear-gradient(to left, rgb(205, 69, 255), rgb(115, 104, 255));
  cursor: pointer;
}

@media (max-width: 900px) {
  .hero {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 6.8rem;
    width: 95%;
    margin: 0 auto;
  }

  .hero_title {
    font-size: 4.8rem;
    grid-row: 1/2;
    grid-column: 1/2;
  }

  .desctiption {
    margin-top: 9.6rem;
    grid-row: 1/2;
    grid-column: 1/2;
    font-size: 1.4rem;
  }
  .hero_img {
    right: 0;
    /* transform: translate(30%, 10%); */
    grid-column: 1/2;
    grid-row: 3/4;
    width: 80%;
    justify-self: center;
  }

  .btns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: stretch;
    margin-top: 0;
    gap: 0;
  }

  #main_button {
    padding: 10px 12px;
    border: 0;
    font-weight: bold;
  }
  #aside_button {
    padding: 10px 12px;
    border: 0;
    font-weight: bold;
  }
}

/* logo相关 */

.logos {
  margin: 0 auto;
  width: 90%;
  display: flex;
  justify-content: center;
  gap: 2.4rem;
}

@media (max-width: 900px) {
  .logos {
    margin-top: 2.4rem;
  }

  .logos img {
    width: 25%;
  }
}

/* gpu  */

.gpu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.gpu_right {
  width: 150%;
  grid-column: 3/4;
  justify-self: right;
  grid-row: 1/2;
}

.gpu_online {
  grid-column: 1/3;
  justify-self: center;
  margin-top: 3.6rem;
}

.gpu_online h2 {
  font-size: 6.8rem;
  color: white;
  letter-spacing: 10px;
  margin-bottom: 1.2rem;
}

.gpu_online h3 {
  font-size: 4.8rem;
  color: #2ebb7d;
  letter-spacing: 20px;
  text-align: center;
}

@media (max-width: 900px) {
  .gpu {
    grid-template-columns: 1fr;
    margin: 0 auto;
    margin-top: 6.8rem;
  }

  .gpu_online {
    grid-column: 1/2;
    grid-row: 1/2;
    z-index: 999;
  }

  .gpu_online h2 {
    font-size: 3.2rem;
  }

  .gpu_online h3 {
    font-size: 4.8rem;
  }

  .gpu_right {
    grid-column: 1/2;
    grid-row: 1/2;
    z-index: 2;
  }

  .gpu_right {
    opacity: 35%;
  }
}

/* 步骤 */

.step {
  padding: 2.4rem;
  margin-bottom: 0;
  background-image: url("../assets/旧移动端插画/bg_2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.step div {
  display: grid;
  width: 90%;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
}

.step div img {
  width: 80%;
}

.step div h3 {
  font-size: 2.4rem;
  color: #00ece2;
}

.step div article p {
  color: white;
  font-size: 1.4rem;
}

.step1 article {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-left: 4.8rem;
  margin-bottom: 4.8rem;
  position: relative;
  z-index: 100;
}

.title01 {
  color: white;
  font-size: 8.8rem;
  position: absolute;
  transform: translate(-20%, -60%);
  left: -10%;
  top: 30%;
  opacity: 10%;
  font-family: "华文琥珀";
}

.step2 article {
  grid-column: 2/3;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-left: 4.8rem;
  margin-bottom: 4.8rem;
  position: relative;
  z-index: 100;
}

.title02 {
  color: white;
  font-size: 8.8rem;
  position: absolute;
  transform: translate(-45%, -60%);
  left: -10%;
  top: 30%;
  opacity: 10%;
  font-family: "华文琥珀";
}

.step3 article {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-left: 4.8rem;
  margin-bottom: 4.8rem;
  position: relative;
  z-index: 100;
}

@media (max-width: 900px) {
  .step div h3 {
    font-size: 2rem;
    color: #00ece2;
  }

  .step {
  }

  .title01 {
    top: 40%;
    left: -5%;
  }

  .title02 {
    left: 10% !important;
  }

  .step {
  }
  .step1 {
    grid-template-columns: 1fr !important;
    margin-bottom: 2.4rem !important;
    gap: 1.2rem;
    margin-top: 2.4rem;
  }

  .step2 {
    grid-template-columns: 1fr !important;
    margin-bottom: 3.6rem !important;
  }

  .step3 {
    grid-template-columns: 1fr !important;
    gap: 1.2rem;
  }

  .step1 article,
  .step2 article,
  .step3 article {
    margin: 0;
  }

  /* .step1 img,
  .step2 img,
  .step3 img {
    width: 50%;
  } */
}

/* 优点页面 */

.features {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #110642;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 8.8rem;
}

.features img {
  margin-top: -4.8rem;
}

/* advantage是优点的文字部分 */
.advantage {
  color: white;
  margin-top: 1.8rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.advantage h2 {
  font-size: 3.6rem;
}

.advantage p {
  font-size: 1.8rem;
}

#advantage_1 {
  color: white;
  font-size: 1.4rem;
  letter-spacing: 4px;
  position: absolute;
  left: 8%;
  width: 15%;
  top: 46%;
}
#advantage_2 {
  color: white;
  font-size: 1.4rem;
  letter-spacing: 4px;
  position: absolute;
  left: 10%;
  width: 15%;
  top: 70%;
}
#advantage_3 {
  color: white;
  font-size: 1.4rem;
  letter-spacing: 4px;
  position: absolute;
  left: 43%;
  width: 15%;
  top: 88%;
}
#advantage_4 {
  color: white;
  font-size: 1.4rem;
  letter-spacing: 4px;
  position: absolute;
  right: 10%;
  width: 15%;
  top: 70%;
}
#advantage_5 {
  color: white;
  font-size: 1.4rem;
  letter-spacing: 4px;
  position: absolute;
  right: 6%;
  width: 15%;
  top: 46%;
}

@media (max-width: 900px) {
  .features img {
    width: 95%;
    margin: 0 auto;
    margin-top: 0rem;
  }

  .advantage h2 {
    font-size: 2.4rem;
  }

  .advantage p {
    font-size: 1.2rem;
  }

  #advantage_1 {
    color: white;
    font-size: 0.8rem;
    letter-spacing: 4px;
    position: absolute;
    left: 4%;
    width: 30%;
    top: 30%;
  }
  #advantage_2 {
    color: white;
    font-size: 0.8rem;
    letter-spacing: 4px;
    position: absolute;
    left: 7%;
    width: 30%;
    top: 70%;
  }
  #advantage_3 {
    color: white;
    font-size: 0.8rem;
    letter-spacing: 4px;
    position: absolute;
    left: 37%;
    width: 30%;
    top: 80%;
  }
  #advantage_4 {
    color: white;
    font-size: 0.8rem;
    letter-spacing: 4px;
    position: absolute;
    right: 7%;
    width: 30%;
    top: 70%;
  }
  #advantage_5 {
    color: white;
    font-size: 0.8rem;
    letter-spacing: 4px;
    position: absolute;
    right: 4%;
    width: 30%;
    top: 30%;
  }
}

/* 商品页 */

.business_img {
  background-image: url("../assets/旧移动端插画/bg_1.jpg");
  background-size: cover;
}

.business {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 90%;
  margin: 0 auto;
  padding-top: 2.4rem;
}

.business article {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: center;
}

.business article h3 {
  font-size: 2.4rem;
  color: #00ece2;
}

.business article p {
  color: white;
  font-size: 1.2rem;
}

.business article img {
  width: 50%;
  opacity: 50%;
}

/* price 定价 */

.price {
  background-image: url("../assets/旧移动端插画/bg_1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 3.6rem 2.4rem;
}

.price_title {
  display: flex;
  justify-content: center;
  color: #eee;
}

.price_title h3 {
  font-size: 3.6rem;
  font-weight: 700;
  font-family: "微软雅黑";
}

.cards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  gap: 1.2rem;
  margin-top: 2.4rem;
  margin-bottom: 6.8rem;
}

.price_card {
  background-color: rgb(30, 17, 81);
  color: rgb(150, 144, 175) !important;
  border-radius: 10px;
  padding: 1.2rem 2.4rem;
}

.price_card h4 {
  font-size: 1.2rem;
  margin-bottom: 1.8rem;
  color: #00ece2;
}

.price_card ol {
  list-style: none;
  line-height: 2;
}

.price_card p {
  margin-top: 1.2rem;
  color: aliceblue;
  font-weight: bold;
  text-align: center;
  font-size: 1rem;
}
