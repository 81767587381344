/* 总 */

.TaskContainer {
  width: 90%;
  margin: auto;
  margin-bottom: 4.8rem;
}

.TaskContainer h1 {
  font-size: 2.4rem;
  margin-bottom: 1.8rem;
}

/* 上部分 */

.Title {
  margin-top: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* 下部分 */

.main {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.2rem;
}

.table_container {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
