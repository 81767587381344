.btn {
  padding: 6px 12px;
  font-size: 1.2rem;
  border-radius: 5px;
  border: 0px;
}

.btn:hover {
  cursor: pointer;
  opacity: 0.8;
}

/*  */

.TaskContainer {
  width: 90%;
  margin: auto;
  margin-bottom: 4.8rem;
}

.TaskContainer h1 {
  font-size: 3.6rem;
  margin-bottom: 1.8rem;
}

/* 当前存在的任务  */

/* .TaskList li {
    width: 100%;
    font-size: 1.8rem;
    padding: 0.6rem;
    cursor: default;
  
    display: grid;
    grid-template-columns: 2fr repeat(2, 1fr);
    border: 1px solid black;
    border-bottom: 0px;
  } */

/* TaskList */

.First {
  padding: 1.2rem;
  border: 1px solid black;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.First h3 {
  font-size: 1.8rem;
}

.Second:nth-child(2) {
  margin-top: 1.2rem;
}

.Second {
  text-decoration: none;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 1fr 1fr;
  justify-content: space-around;
  align-items: center;
  letter-spacing: 3px;
  padding: 4px 0;
  padding-left: 1.2rem;
  border: 1px solid black;
  margin-bottom: 5px;
}

.Second p {
  font-size: 1.2rem;
}

/*  */

.Title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* 输入框  */

.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
}

.inputContent {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.modal {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.block label {
  font-size: 1rem;
}

.block input {
  font-size: 1rem;
  padding: 4px 8px;
}

.block select {
  font-size: 1rem;
  padding: 4px 8px;
  width: 100%;
}

.block {
  padding: 4px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
}

.buttonBlock {
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  gap: 1.2rem;
}

.inputModify {
  width: 18px;
  height: 18px;
}

@media (max-width: 700px) {
  .Title {
    display: grid;
    margin-bottom: 2.4rem;
  }

  .main {
    display: grid;
    grid-template-columns: 1fr;
  }

  .block {
    font-size: 1.8rem;
    grid-template-columns: 2fr 5fr;
  }

  .block input {
    width: 100%;
  }
}
