.drop_menu {
  display: none;
}

.menu {
  display: block;
}

.tianchong {
  display: none;
}

@media (max-width: 900px) {
  .tianchong {
    display: block;
  }
  .menu {
    display: none;
  }

  .drop_menu {
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: white;
    width: 100%;
    padding: 1px 10px;
    display: block;
  }
}
