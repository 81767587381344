/* 基础 */

#client {
  width: 100%;
  margin: 0 auto;
}

/* 顶部导航 */

#top_nav {
  display: flex;
  justify-content: space-between;
  margin: 10px auto;
  width: 90%;
}

#top_nav h2 {
  font-size: 1rem;
  /* color: #fff; */
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-weight: 500;
}

#top_nav_right {
  display: flex;
  gap: 1.2rem;
  align-items: center;
}

#nav_logo {
  width: 28px;
}

/* 下方 */

#下方 {
  display: flex;
  gap: 1.2rem;
  width: 90%;
  margin: 0 auto;
}

/* 内容区域 */

#content {
  width: 100%;
}

#btns {
  display: flex;
  margin-bottom: 8px;
}

.包类型 {
  display: flex;
  align-items: center;
  gap: 8px;
}
