/* 中间的Tips部分 */

.tips p {
  font-size: 1.2rem;
}

.tips {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

@media (max-width: 900px) {
}

/* 任务部分 */

.task_section {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
  margin-top: 1.8rem;
  overflow: hidden;
}

@media (max-width: 900px) {
  .task_section {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.2rem;
    margin-top: 1.8rem;
    padding: 0;
    width: 90%;
  }

  .task_section article {
    border: 1px solid black;
    border-radius: 8px;
    transition: all 0.5s;
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
    padding: 0;
    overflow: hidden;
    padding: 4px 8px;
  }

  .task_section article h2 {
    font-size: 1.2rem;
    margin-bottom: 8px;

    overflow: hidden;
  }

  .badge {
  }

  .card {
  }
}

/* modal模态窗口 */

.finished_modal p {
  font-size: 1.2rem;
  margin-bottom: 2px;
  letter-spacing: 1.2px;
}

/* tool */

.display_none {
  display: none;
}
