/* 最上方的信息部分 */
.info_section {
  width: 90%;
  margin: 0 auto;
  margin-top: 3.6rem;
  font-family: "ZCOOL XiaoWei", sans-serif;
}

.title {
  display: flex;
  gap: 1.8rem;
  align-items: center;
}
.title h2 {
  font-size: 2.2rem;
}

.desctiption {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1.8rem;
}

.desctiption article {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.desctiption article p {
  font-size: 1.1rem;
}

.desctiption article h3 {
  font-size: 1.4rem;
}

.impove_level {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.2rem;
  justify-items: stretch;
}

.money {
  color: #2f9e44;
  font-weight: bold;
  font-size: 1.6rem;
}

@media (max-width: 900px) {
  .desctiption {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.8rem;
    margin-top: 1.8rem;
  }

  .title h2 {
    font-size: 1.6rem;
  }

  .impove_level {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: stretch;
  }
}

#renwuzhuangtai {
  gap: 2.4rem;
}
