.container {
  display: grid;
  justify-items: center;
}

.charge {
  font-size: 2.4rem;
  font-weight: bold;
}

.charge:last .img {
  background-color: red;
}

#dingdanhao {
  font-size: 1.2rem;
  align-self: center;
  text-align: center;
}

#alipay {
  margin-top: 1.2rem;
  width: 100px;
}
