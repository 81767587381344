.container {
  width: 90%;
  margin: 0 auto;
  margin-top: 2.4rem;
}

@media (max-width: 900px) {
  .container {
    margin-top: 0;
  }
}

.container section h1 {
  margin-bottom: 1.2rem;
}

/* 今日概览 */

.gailan h1 {
  margin-bottom: 1.2rem;
  display: none;
}

#dingdan {
  font-size: 3.6rem;
  display: flex;
  justify-content: center;
}
#chudan {
  font-size: 3.6rem;
  display: flex;
  justify-content: center;
}
#yingshou {
  font-size: 3.6rem;
  display: flex;
  justify-content: center;
  color: #2b8a3e;
}

@media (max-width: 900px) {
  .card_title {
    font-size: 0.9rem;
  }
  #dingdan {
    font-size: 1.8rem;
  }
  #chudan {
    font-size: 1.8rem;
  }
  #yingshou {
    font-size: 1.8rem;
  }
}

.card_title {
  display: flex;
  justify-content: center;
}

/* 统计数据 */

.shujutongji {
  margin-top: 1.2rem;
}

@media (max-width: 900px) {
  .shujutongji h1 {
    font-size: 1.2rem;
  }
}
