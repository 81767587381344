/* nav */

.hander_nav {
  display: grid;
  grid-template-columns: 2fr 10fr;
  gap: 1.2rem;
}

@media (max-width: 900px) {
  .hander_nav {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
}
