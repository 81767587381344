/* 基本 */

:root {
  --theme-color-light: #ffffff; /* 亮色主题背景色 */
  --theme-color-dark: rgb(18, 6, 65); /* 暗色主题背景色 */
  --theme-text-color-light: #333333; /* 亮色主题文字色 */
  --theme-text-color-dark: #ffffff; /* 暗色主题文字色 */
}

/* footer */

[data-theme="light"] {
  background-color: var(--theme-color-light);
}

[data-theme="light"] p,
[data-theme="light"] a,
[data-theme="light"] h3,
[data-theme="light"] li {
  color: var(--theme-text-color-light) !important;
}

[data-theme="dark"] {
  background-color: var(--theme-color-dark);
  height: 110vh;
}

footer {
  /* background-color: ; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 0;
  width: 100%;
  padding: 4.8rem 8%;
  gap: 1.8rem;

  position: relative;
}

.about_us {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2.4rem;
  gap: 1.2rem;
}

footer div h3 {
  color: white;
  font-weight: 500;
}

.about_us img {
  width: 35%;
}

.about_us p {
  color: white;
  line-height: 1.8;
  letter-spacing: 3px;
}

.bg_color {
  padding-top: 4.8rem;
  background-color: #110642;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  justify-self: center;
}

.list p,
.list a {
  color: white;
}

#法律声明 {
  display: flex;
  color: white;
  flex-direction: column;
  gap: 5px;
  justify-self: center;
}

#法律声明 h3 {
  margin-bottom: 12px;
}

#法律声明 ul {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

#法律声明 a {
  color: white;
  text-decoration: underline;
}

@media (max-width: 900px) {
  footer {
    grid-template-columns: 1fr;
  }

  .list {
    justify-self: start;
  }

  .about_us h2 {
    font-size: 1rem;
  }

  footer div h3 {
    font-size: 1rem !important;
    font-weight: 700;
  }
}

#页脚宫殿 {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
